import React from "react"

export default props => {
    return (
        <>
            <div class="pageHeader">
                <div class="content">
                    <h1>{props.title}</h1>
                </div>
            </div>
        </>
    )
}
