import React from "react"

import "../styles/privacy.scss"

import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import Section from "../components/section"

const PrivacyPage = () => {
    return (
        <Layout>
            <SEO title="Privacy Policy" />
            <PageHeader title="Privacy Policy" />
            <Section sectionClass="privacy">
                <div className="widthContainer">
                    <p>Effective from 01 February 2020</p>

                    <h2>INTRODUCTION</h2>
                    <p>
                        Based in the United Kingdom, Swift Walkies is a world leader in the design and development of
                        connected solutions. Swift Walkies is committed to ensuring your privacy and personal
                        information is protected; it is the data controller of your personal information and is
                        responsible for complying with data protection laws.
                    </p>
                    <p>
                        This Privacy Statement describes how we process (collect, use and share) personal information.
                        By providing your personal information, you acknowledge that we may process it as set out in
                        this policy.
                    </p>
                    <p>The following topics are presented in this Privacy Statement:</p>
                    <ul>
                        <li>Collecting personal information</li>
                        <li>Collecting other information</li>
                        <li>Using and retaining personal information</li>
                        <li>Protecting personal information</li>
                        <li>Your rights to access personal information</li>
                        <li>Changes to this privacy statement</li>
                        <li>Contact us</li>
                    </ul>

                    <h2>COLLECTING PERSONAL INFORMATION</h2>
                    <p>
                        Swift Walkies has a legitimate interest to process personal data relating to decision makers,
                        budget holders, event participants and other interest groups.
                    </p>
                    <p>
                        The collection and use of information from a number of sources is essential to our company's
                        ability to provide our services.
                    </p>
                    <p>
                        We collect information that identifies you or could reasonably be used to identify you as an
                        individual, which we refer to here as Personal Data. The personal data that we may collect
                        includes company contact details, such as name, job title, postal address, telephone numbers
                        email addresses, and financial and transaction data, such as credit or debit card numbers used
                        in relation to our services.
                    </p>
                    <p>We will only use information that we collect about you lawfully in accordance with GDPR.</p>
                    <p>
                        In addition, the privacy statement will also be included within each email sent by Swift
                        Walkies. employees as well as being linked on the Swift Walkies website.
                    </p>
                    <p>
                        We also collect information from data provided by you as a delegate or participant attending any
                        Swift Walkies event (or by someone acting on your behalf).
                    </p>
                    <p>
                        We will collect personal data from you when you register interest on our booth at a tradeshow
                        event or conference, or directly from the Swift Walkies website, via an online form when you
                        download any registration content or obtain a quotation request.
                    </p>
                    <p>
                        By filling in an online form on the Swift Walkies website, you will consent to the collection,
                        use, disclosure, storage and processing of personal data in accordance with this Privacy
                        Statement and if opted in, agree to receive marketing updates on behalf of Swift Walkies.
                    </p>
                    <p>
                        We will collect personal data received directly from related individuals by email, telephone
                        call or by business card at networking events, conferences or from third parties acting on
                        behalf of the individuals and supplier invoices.
                    </p>
                    <p>
                        Data is also gathered from publicly available sources; corporate social media, related business
                        platforms, e-newsletter respondents and posted job vacancies.
                    </p>

                    <h2>HOW WE PROCESS PERSONAL DATA</h2>
                    <p>
                        The personal data we collect is stored in various internal databases, including our CRM
                        databases. These systems are all owned, controlled and hosted by Swift Walkies
                    </p>
                    <p>
                        We hold personal data only for as long as there is legitimate reason to hold the data, or for as
                        long as is necessary thereafter for legislative purposes.
                    </p>

                    <h2>HOW WE USE PERSONAL DATA</h2>
                    <p>We use personal data to:</p>
                    <ul>
                        <li>
                            Email or contact you about similar products or services we think may be of interest to you.
                        </li>
                        <li>
                            Send you marketing communications via the post and via electronic means such as
                            e-newsletters and email correspondence.
                        </li>
                        <li>Provide you with information, products or services that you request from us.</li>
                        <li>Respond to your enquiries and provide customer support.</li>
                        <li>
                            Process your orders, and to issue your invoices by post or emails and to collect payments
                            and to make payments to suppliers where applicable.
                        </li>
                        <li>Notify you about changes to our services.</li>
                        <li>Improve and modify our services.</li>
                    </ul>

                    <h2>DATA DISCLOSURE</h2>
                    <p>The data we gather about you will never be sold on to a third party.</p>
                    <p>
                        In certain circumstances, we may need to disclose your data to the appropriate third parties if
                        required to do so by law or an authorised government body.
                    </p>

                    <h2>MARKETING</h2>
                    <p>
                        We would like to send you information about products and services of ours which may be of
                        interest to you. The products and services will always relate to products and services of
                        swiftwalkies.co.uk
                    </p>
                    <p>
                        If you sign up for the marketing communication, your transactional and usage data in addition
                        will be analysed by Swift Walkies to tailor commercial communication specifically to your
                        preferences.
                    </p>
                    <p>
                        You have a right at any time to stop us from contacting you for marketing, and you may opt out
                        at a later date.
                    </p>
                    <p>
                        If you no longer wish to be contacted, for marketing purposes, please click
                        <a href="mailto:swiftwalkies@gmail.com?subject=Unsubscribe">here</a> to unsubscribe.
                    </p>

                    <h3>Legitimate Interest</h3>
                    <p>
                        The GDPR states, ‘the processing of personal data for direct marketing purposes may be regarded
                        as carried out for a legitimate interest’.
                    </p>
                    <p>
                        We process your personal data for our legitimate business interests. e.g. direct marketing,
                        modifying or improving our services. Click
                        <a
                            href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/legitimate-interests/"
                            target="_blank"
                            rel="noopener noreferrer">
                            here
                        </a>
                        to learn more about ‘legitimate interest’.
                    </p>
                    <p>
                        You have the right to object to this processing if you wish and if you wish to do so please
                        click <a href="mailto:swiftwalkies@gmail.com?subject=GDPR Objection">here</a>.
                    </p>

                    <h2>SECURITY</h2>
                    <p>
                        The security of your data is important to us. We have implemented technology and security
                        policies, rules and measures designed to protect the personal data under our control, both on or
                        off-line from unauthorised access, improper use, alteration, unlawful or accidental destruction
                        and accidental loss.
                    </p>
                    <p>
                        Off-line, all of our personal data is restricted in our offices. Only employees of Swift
                        Walkies. are granted access to such information. Please remember, however, that no data
                        transmission over the internet can be guaranteed as totally secure.
                    </p>
                    <p>
                        There are exceptions where, for those opted in to receive marketing updates, we upload email
                        addresses to our third-party marketing email automation platform for email marketing.
                    </p>
                    <p>
                        These service providers are provided only with the personal data that they need to perform their
                        services and are not permitted to use or disclose your personal data for other purposes without
                        your prior authorisation.
                    </p>

                    <h2>ACCESS TO YOUR INFORMATION AND CORRECTION</h2>
                    <p>You have the right to request a copy of the information that we hold about you.</p>
                    <p>
                        If you would like a copy of some or all of the personal data we hold, please email Swift
                        Walkies. <a href="mailto:swiftwalkies@gmail.com?subject=GDPR Request">here</a> or write to the
                        following address: GDPR officer, Swift Walkies, 6-8 Bond Terrace, Wakefield, UK, WF1 2HW
                    </p>
                    <p>
                        In certain circumstances we reserve the right to charge a reasonable fee to comply with your
                        request.
                    </p>
                    <p>
                        We want to make sure that your personal information is accurate and up to date. You may ask us
                        to correct or remove data that is inaccurate.
                    </p>
                    <p>
                        However, the data will be retained, and processed, for suppression purposes i.e. to ensure that
                        it cannot be added to the database again or to ensure we are fulfilling all legislative and
                        contractual requirements.
                    </p>

                    <h2>COMPLAINTS</h2>
                    <p>
                        Individuals have the right to complain to the Information Commissioner if they believe there is
                        a problem with the way their data is being used.
                    </p>
                    <p>
                        Follow the link to contact
                        <a href="https://ico.org.uk/concerns/" target="_blank" rel="noopener noreferrer">
                            ICO CONCERNS
                        </a>
                    </p>

                    <h2>DISCLOSURES REQUIRED BY LAW</h2>
                    <p>
                        Your personal information will be disclosed where we are obliged by law to do so. We may also
                        disclose your personal information where we are allowed by law to protect or enforce our rights
                        or the rights of others and for the detection and prevention of crimes, such as fraud.
                    </p>

                    <h2>COOKIES</h2>
                    <p>We use cookies to help make is easier for you to access our website.</p>
                    <p>
                        Cookies are pieces of information that a website transfers to your computer's hard disk so that
                        the website can remember who you are.
                    </p>
                    <p>
                        Most websites use cookies. Cookies cannot be used by themselves to identify you. Cookies are
                        also used to collect information about how visitors use our site. We use the information to
                        compile reports and to help us improve the site.
                    </p>
                    <p>
                        The cookies collect information in an anonymous form, including the number of visitors on the
                        site, where visitors have come to the site from and the pages they visited. By using our
                        website, you agree that we can place these types of cookies on your computer. You may prevent us
                        from storing a cookie on your computer by setting your browser so that it will not accept
                        cookies.
                    </p>

                    <h2>OTHER WEBSITES</h2>
                    <p>
                        Swift Walkies's website may contain links to other sites. When you choose to visit those sites,
                        we cannot assume responsibility for any other site's content or information handling practices.
                        We encourage you to review each site's privacy policy prior to entering into transactions with
                        the third party-linked site.
                    </p>

                    <h2>CHANGES TO PRIVACY POLICY</h2>
                    <p>
                        Any changes we may make to this privacy policy in the future will be posted on this page. We
                        encourage you to check this privacy policy from time to time for any updates or changes to the
                        privacy policy. If we would like to use your previously collected personal data for different
                        purposes than those we notified you about at the time of collection, we will provide you with
                        notice and, where required by law, seek your consent before using your personal data for a new
                        or unrelated purpose.
                    </p>
                    <p>
                        We may process your personal data without your knowledge or consent where required by applicable
                        law or regulation.
                    </p>
                    <p>This policy was last updated on 12 March 2019.</p>

                    <h2>PRIVACY SUPPORT</h2>
                    <p>
                        Please contact us if you have any questions about our privacy policy or information we hold
                        about you by emailing swiftwalkies@gmail.com
                    </p>
                </div>
            </Section>
        </Layout>
    )
}

export default PrivacyPage
